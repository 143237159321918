import React, {Component} from 'react';

import {Link} from "react-router-dom";
import {withSharedDataContext} from './../SharedDataContext';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

class PatternList extends Component {
  state = {};

  render() {
    const patternNames = this.props.sharedDataContext.patternNames || [];

    return (
      <React.Fragment>
        <Typography variant="h4" gutterBottom>
          Scratch Patterns
        </Typography>

        <List component="nav">
          {patternNames.map(patternName => (
            <ListItem key={patternName.key} button component={Link} to={`/patterns/${patternName.key}`}>
              <ListItemText primary={patternName.name}/>
            </ListItem>
          ))}
        </List>

      </React.Fragment>
    );
  }
}

export default withSharedDataContext(PatternList);
