import React, {Component} from 'react';

import Typography from '@material-ui/core/Typography';

import TTMChart from "../ttm-chart/TTMChart";
import YoutubeBox from "./YoutubeBox";

import {withSharedDataContext} from './../SharedDataContext';


class PatternView extends Component {
  state = {};

  getPatternKey = () => {
    return this.props.match.params.key;
  };

  updateChartConf = () => {
    const patternKey = this.getPatternKey();
    this.props.sharedDataContext.loadPatternConfByKey(patternKey);
  };

  componentDidMount() {
    this.updateChartConf();
  }

  componentDidUpdate(prevProps) {
    if (this.getPatternKey() !== prevProps.match.params.key) {
      this.updateChartConf();
    }
  }

  render() {
    const patternConf = this.props.sharedDataContext.currentPatternConf;
    const hasPatternConf = Object.keys(patternConf).length;

    let youtubeIds = [];
    if (hasPatternConf) {
      youtubeIds = patternConf.youtubes;
    }
    const hasYoutubeIds = youtubeIds.length > 0;

    return (
      <React.Fragment>

        <Typography variant="h4" gutterBottom>
          {patternConf.name}
        </Typography>

        {hasPatternConf && <TTMChart patternConf={patternConf}/>}
        <br/>
        <br/>
        {hasPatternConf && <div>
          {patternConf.descriptions.map(descr =>
            <div key={descr}>{descr}</div>
          )}
        </div>}
        <br/>
        <br/>
        <br/>
        {hasYoutubeIds && <YoutubeBox youtubeIds={youtubeIds}/>}


      </React.Fragment>
    );
  }
}

export default withSharedDataContext(PatternView);
