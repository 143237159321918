import React, {Component} from 'react';

import Typography from '@material-ui/core/Typography';

import YoutubeBox from './../pattern/YoutubeBox';

class Home extends Component {
  state = {};

  render() {

    return (
      <React.Fragment>

        <Typography variant="h5" component="h3">
          Welcome to TTMr!
        </Typography>
        <br/>

        <Typography component="p">
          <strong>Turntablist Transcription Methodology (TTM)</strong> is a notation system for scratching and turntablism designed by John Carluccio, Ethan Imboden and by Raymond Pirtle (DJ Raedawn).<br/>
          It is intended to be an intuitive <strong>graphical representation of the movement of a record</strong> whilst performing with a turntable, and was originally documented in a booklet form for distribution. (Wikipedia)
        </Typography>
        <br/>

        <Typography component="p">
          Download <a href={`https://www.studioscratches.com/wp-content/uploads/2011/01/TTMv1_Eng.pdf`}>official TTM Handbook v1.0</a> as PDF
        </Typography>
        <br/>

        <YoutubeBox youtubeIds={['wsKpqJ-g388']}/>
        <br/>
        <br/>

        <Typography component="p">
          This app is a collection of TTM patterns for a various set of scratches. It's meant to be a tool for practicing sessions and as a library.
        </Typography>




      </React.Fragment>
    );
  }
}

export default Home;
