import React, {Component} from 'react';
import {withStyles} from "@material-ui/core";
import PropTypes from "prop-types";

import Hidden from "@material-ui/core/Hidden/Hidden";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer/SwipeableDrawer";
import Drawer from "@material-ui/core/Drawer/Drawer";

import Header from './Header';
import Main from './Main';
import Sidebar from './Sidebar';

const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    paddingTop: theme.spacing.unit * 7,
  },
});


class Page extends Component {
  state = {
    mobileOpen: false,
  };

  handleDrawerToggle = () => {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }));
  };
  handleCloseDrawerMobile = () => {
    if (this.state.mobileOpen === true) {
      this.handleDrawerToggle();
    }
  };

  render() {
    const { classes, theme } = this.props;

    return (
      <div className={classes.root}>

        <Header onHandleDrawerToggle={this.handleDrawerToggle}/>

        <nav className={classes.drawer}>
          <Hidden smUp implementation="css">
            <SwipeableDrawer
              container={this.props.container}
              variant="temporary"
              anchor={theme.direction === 'rtl' ? 'right' : 'left'}
              open={this.state.mobileOpen}
              onClose={this.handleDrawerToggle}
              onOpen={this.handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              <Sidebar onCloseDrawerMobile={this.handleCloseDrawerMobile}/>
            </SwipeableDrawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              <Sidebar/>
            </Drawer>
          </Hidden>
        </nav>

        <main className={classes.content}>
          <Main/>
        </main>

      </div>
    );
  }
}


Page.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Page);
