import React, {Component} from 'react';


class NotFound extends Component {

  render() {

    return (
      <React.Fragment>

        <div>404 - Not Found</div>


      </React.Fragment>
    );
  }
}

export default NotFound;
