import React, {Component} from 'react';

import {Line} from 'react-chartjs-2';

class TTMChart extends Component {
  state = {
    chartData: {
      datasets: [],
    },
    chartOptions: {
      scales: {
        xAxes: [{
          type: 'linear',
          position: 'bottom',
          ticks: {
            min: 1,
            max: 5, // will be replaced dynamically
            stepSize: 0.5,
            callback: function (label, index, labels) {
              if (index === labels.length - 1) {
                // hide last one
                return '';
              }

              while (label >= 5) {
                // always count 12341234
                label -= 4;
              }

              if (Number.isInteger(label)) {
                return label;
              }
              return '';
              //return Math.floor(label) + 'a';
            }
          },
          gridLines: {
            lineWidth: 1,
            drawBorder: true
          },
        }],
        yAxes: [{
          display: false,
        }],
      },
      legend: {
        display: false,
      },
      tooltips: {
        enabled: false,
      },
      layout: {
        padding: {
          left: 15,
          right: 15,
          top: 10,
          bottom: 0
        }
      },
      responsive: true,
    },
  };

  createDatasetFromPatternConf = (patternConf) => {
    let datasets = [];

    const datasetOpenFaderPositions = {
      label: 'openfader_' + patternConf.key,
      backgroundColor: 'white',
      borderColor: 'black',
      borderWidth: 3,
      data: patternConf.pattern.openFaderPositions,
      fill: false,
      pointRadius: 10,
      pointHoverRadius: 10,
      showLine: false,
    };
    datasets.push(datasetOpenFaderPositions);

    const datasetClosedFaderPositions = {
      label: 'clicks' + patternConf.key,
      backgroundColor: 'black',
      borderColor: 'black',
      data: patternConf.pattern.closedFaderPositions,
      fill: false,
      pointRadius: 10,
      pointHoverRadius: 10,
      showLine: false,
    };
    datasets.push(datasetClosedFaderPositions);

    patternConf.pattern.lines.forEach((line, index) => {
      const datasetLine = {
        label: 'line_'  + patternConf.key + '_' + index,
        fill: false,
        data: line,
        pointRadius: 0,
        borderWidth: 5,
        borderColor: 'black',
        lineTension: 0,
        borderJoinStyle: 'round',
      };

      datasets.push(datasetLine);
    });

    return datasets;
  };

  getMaxXFromDatasets = (datasets) => {
    let max = 5;

    datasets.forEach(dataset => {
      dataset.data.forEach(point => {
        if (point.x > max) {
          max += 5;
        }
      })
    });

    return max;
  };


  updateChartData = () => {
    const patternConf = this.props.patternConf;
    const datasets = this.createDatasetFromPatternConf(patternConf);
    const chartData = {datasets: datasets};

    const chartOptions = this.state.chartOptions;
    chartOptions.scales.xAxes[0].ticks.max = this.getMaxXFromDatasets(datasets);

    this.setState({chartData: chartData, chartOptions: chartOptions});
  };

  componentDidMount() {
    this.updateChartData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.patternConf !== prevProps.patternConf) {
      this.updateChartData();
    }
  }

  render() {
    return (
      <React.Fragment>
        <Line
          data={this.state.chartData}
          options={this.state.chartOptions}
          height={100}
          width={200}
        />
      </React.Fragment>
    );
  }
}

export default TTMChart;
