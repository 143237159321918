import React, {Component} from 'react';
import {BrowserRouter as Router} from "react-router-dom";

import CssBaseline from '@material-ui/core/CssBaseline'

import { SharedDataProvider } from './components/SharedDataContext';
import Page from './components/page/Page';


class App extends Component {
  state = {};

  render() {
    return (
      <React.Fragment>
        <CssBaseline/>
        <Router>
          <React.Fragment>

            <SharedDataProvider>
              <Page/>
            </SharedDataProvider>

          </React.Fragment>
        </Router>
      </React.Fragment>
    );
  }
}

export default App;
