const patterns = [

  {
    key: 'aquaman',
    name: 'Aqua Man',
    aliases: [],
    pattern: {
      lines: [
        [
          {"x": 1, "y": 0},
          {"x": 1.5, "y": 1},
          {"x": 2, "y": 0},
          {"x": 2.5, "y": 1},
          {"x": 3, "y": 0},
          {"x": 3.5, "y": 1},
          {"x": 4, "y": 0},
        ]
      ],
      openFaderPositions: [
        {"x": 1, "y": 0},
        {"x": 4, "y": 0},
      ],
      closedFaderPositions: [
        {"x": 1.5, "y": 1},
        {"x": 3, "y": 0},
        {"x": 3.5, "y": 1},
      ],
    },
    descriptions: [
    ],
    youtubes: [
      '7GXOhZbuvIg',
    ]
  },

  {
    key: 'autobahn-short',
    name: 'Autobahn (short)',
    aliases: [],
    pattern: {
      lines: [
        [
          {"x": 1, "y": 0},
          {"x": 1.5, "y": 0.5},
          {"x": 2, "y": 0},
          {"x": 2.5, "y": 0.5},
          {"x": 3, "y": 1},
          {"x": 3.5, "y": 0.5},
          {"x": 4, "y": 0},
        ]
      ],
      openFaderPositions: [],
      closedFaderPositions: [
        {"x": 1, "y": 0},
        {"x": 2, "y": 0},
        {"x": 2.5, "y": 0.5},
        {"x": 3.5, "y": 0.5},
        {"x": 4, "y": 0},
      ],
    },
    descriptions: [
      'Combo: slice, stab, slice, reverse stab',
      'Boomerang is a good pre-practice',
    ],
    youtubes: [
      'ETrySwUf4_M',
    ]
  },

  {
    key: 'autobahn-slow',
    name: 'Autobahn (slow)',
    aliases: [],
    pattern: {
      lines: [
        [
          {"x": 1, "y": 0},
          {"x": 1.5, "y": 0.5},
          {"x": 2, "y": 0},
          {"x": 3, "y": 1},
          {"x": 3.5, "y": 0.5},
          {"x": 4, "y": 1},
          {"x": 5, "y": 0},
        ]
      ],
      openFaderPositions: [],
      closedFaderPositions: [
        {"x": 1, "y": 0},
        {"x": 2, "y": 0},
        {"x": 2.5, "y": 0.5},
        {"x": 3.5, "y": 0.5},
        {"x": 4.5, "y": 0.5},
        {"x": 5, "y": 0},
      ],
    },
    descriptions: [
    ],
    youtubes: [
      '7hgsfUL7odo',
    ]
  },

  // https://www.studioscratches.com/the-definitive-fast-autobahn-scratch-tutorial/
  {
    key: 'autobahn-fast',
    name: 'Autobahn (fast)',
    aliases: [],
    pattern: {
      lines: [
        [
          {"x": 1, "y": 0},
          {"x": 1.5, "y": 0.33},
          {"x": 2, "y": 0},
          {"x": 3, "y": 0.66},
          {"x": 3.5, "y": 0.33},
          {"x": 4.5, "y": 1},
          {"x": 5, "y": 0.66},
          {"x": 5.5, "y": 0},
        ]
      ],
      openFaderPositions: [],
      closedFaderPositions: [
        {"x": 1, "y": 0},
        {"x": 2, "y": 0},
        {"x": 2.5, "y": 0.33},
        {"x": 3.5, "y": 0.33},
        {"x": 4, "y": 0.66},
        {"x": 5, "y": 0.66},
      ],
    },
    descriptions: [
    ],
    youtubes: [
      'nqzwiWkKV_s',
    ]
  },

  {
    key: 'beetle',
    name: 'Beetle',
    aliases: [],
    pattern: {
      lines: [
        [
          {"x": 1, "y": 0},
          {"x": 1.5, "y": 1},
          {"x": 2, "y": 0},
          {"x": 2.5, "y": 1},
          {"x": 3, "y": 0},
          {"x": 3.5, "y": 1},
          {"x": 4, "y": 0},
        ]
      ],
      openFaderPositions: [
        {"x": 1, "y": 0},
        {"x": 4, "y": 0},
      ],
      closedFaderPositions: [
        {"x": 1.75, "y": 0.5},
        {"x": 3.25, "y": 0.5},
      ],
    },
    descriptions: [
    ],
    youtubes: [
      'vLvvgfSq84A',
    ]
  },

  {
    key: 'boomerang',
    name: 'Boomerang',
    aliases: [
      "Delayed 2c Flare"
    ],
    pattern: {
      lines: [
        [
          {"x": 1, "y": 0},
          {"x": 1.33, "y": 1},
          {"x": 1.66, "y": 0},
          {"x": 2, "y": 1},
          {"x": 2.33, "y": 0},
          {"x": 2.66, "y": 1},
          {"x": 3, "y": 0},
        ]
      ],
      openFaderPositions: [
      ],
      closedFaderPositions: [
        {"x": 1, "y": 0},
        {"x": 1.66, "y": 0},
        {"x": 2, "y": 1},
        {"x": 2.66, "y": 1},
        {"x": 3, "y": 0},
      ],
    },
    descriptions: [
    ],
    youtubes: [
      '-Ne8BwqkCTc',
    ]
  },

  {
    key: 'boomflare',
    name: 'Boomflare',
    aliases: [
    ],
    pattern: {
      lines: [
        [
          {"x": 1, "y": 0},
          {"x": 1.33, "y": 0.33},
          {"x": 1.66, "y": 0},
          {"x": 2, "y": 0.33},
          {"x": 2.33, "y": 0},
          {"x": 3.33, "y": 1},
          {"x": 4.33, "y": 0},
          {"x": 4.66, "y": 0.33},
          {"x": 5, "y": 0},
        ]
      ],
      openFaderPositions: [
      ],
      closedFaderPositions: [
        {"x": 1, "y": 0},
        {"x": 1.66, "y": 0},
        {"x": 2, "y": 0.33},
        {"x": 2.66, "y": 0.33},
        {"x": 3, "y": 0.66},
        {"x": 3.66, "y": 0.66},
        {"x": 4, "y": 0.33},
        {"x": 4.66, "y": 0.33},
        {"x": 5, "y": 0},
      ],
    },
    descriptions: [
    ],
    youtubes: [
      '4OlArBtIN8o',
    ]
  },

  {
    key: 'broken-orbits',
    name: 'Broken Orbits',
    aliases: [
    ],
    pattern: {
      lines: [
        [
          {"x": 1, "y": 0},
          {"x": 1.5, "y": 1},
          {"x": 2, "y": 0},
          {"x": 2.5, "y": 1},
          {"x": 3, "y": 0},
          {"x": 3.5, "y": 1},
          {"x": 4, "y": 0},
        ]
      ],
      openFaderPositions: [
        {"x": 1, "y": 0},
        {"x": 4, "y": 0},
      ],
      closedFaderPositions: [
        {"x": 1.25, "y": 0.5},
        {"x": 1.66, "y": 0.66},
        {"x": 1.83, "y": 0.33},
        {"x": 2.16, "y": 0.33},
        {"x": 2.32, "y": 0.66},
        {"x": 2.75, "y": 0.5},
        {"x": 3.16, "y": 0.33},
        {"x": 3.32, "y": 0.66},
        {"x": 3.66, "y": 0.66},
        {"x": 3.83, "y": 0.33},
      ],
    },
    descriptions: [
    ],
    youtubes: [
      '7oLSfgVU2Gc',
    ]
  },

  {
    key: 'chirp',
    name: 'Chirp',
    aliases: [],
    pattern: {
      lines: [
        [
          {"x": 1, "y": 0},
          {"x": 1.5, "y": 1},
          {"x": 2, "y": 0},
        ]
      ],
      openFaderPositions: [
        {"x": 1, "y": 0},
        {"x": 2, "y": 0},
      ],
      closedFaderPositions: [
        {"x": 1.5, "y": 1},
      ],
    },
    descriptions: [
    ],
    youtubes: [
      'PEizkB2PAb8',
    ]
  },

  {
    key: 'chirp-flare',
    name: 'Chirp Flare',
    aliases: [
      'Flirps'
    ],
    pattern: {
      lines: [
        [
          {"x": 1, "y": 0},
          {"x": 1.5, "y": 0.5},
          {"x": 2, "y": 0},
          {"x": 3, "y": 1},
          {"x": 4, "y": 0},
        ]
      ],
      openFaderPositions: [
        {"x": 1, "y": 0},
        {"x": 4, "y": 0},
      ],
      closedFaderPositions: [
        {"x": 1.5, "y": 0.5},
        {"x": 2.5, "y": 0.5},
        {"x": 3.5, "y": 0.5},
      ],
    },
    descriptions: [
    ],
    youtubes: [
      'bH_WQuLSTeU'
    ]
  },

  {
    key: 'chirp-flare-high',
    name: 'Chirp Flare (high)',
    aliases: [
    ],
    pattern: {
      lines: [
        [
          {"x": 1, "y": 0},
          {"x": 1.5, "y": 1},
          {"x": 2, "y": 0},
          {"x": 2.5, "y": 1},
          {"x": 3, "y": 0},
        ]
      ],
      openFaderPositions: [
        {"x": 1, "y": 0},
        {"x": 3, "y": 0},
      ],
      closedFaderPositions: [
        {"x": 1.5, "y": 1},
        {"x": 2.25, "y": 0.5},
        {"x": 2.75, "y": 0.5},
      ],
    },
    descriptions: [
    ],
    youtubes: [
    ]
  },

  {
    key: 'chirp-og-flare',
    name: 'Chirp OG Flare',
    aliases: [],
    pattern: {
      lines: [
        [
          {"x": 1, "y": 0},
          {"x": 1.5, "y": 0.5},
          {"x": 2, "y": 0},
          {"x": 3, "y": 1},
          {"x": 3.5, "y": 0},
        ]
      ],
      openFaderPositions: [
        {"x": 1, "y": 0},
        {"x": 3.5, "y": 0},
      ],
      closedFaderPositions: [
        {"x": 1.5, "y": 0.5},
        {"x": 2.5, "y": 0.5},
        {"x": 3, "y": 1},
      ],
    },
    descriptions: [
    ],
    youtubes: [
      'T2-0YmmzGxw',
    ]
  },

  {
    key: 'delete',
    name: 'Delete',
    aliases: [],
    pattern: {
      lines: [
        [
          {"x": 1, "y": 0},
          {"x": 1.5, "y": 0.5},
          {"x": 2, "y": 0},
          {"x": 3, "y": 1},
          {"x": 3.5, "y": 0.5},
          {"x": 4, "y": 1},
          {"x": 5, "y": 0},
        ]
      ],
      openFaderPositions: [
        {"x": 1, "y": 0},
        {"x": 5, "y": 0},
      ],
      closedFaderPositions: [
        {"x": 2.5, "y": 0.5},
        {"x": 4.5, "y": 0.5},
      ],
    },
    descriptions: [
    ],
    youtubes: [
      'Hpzb9wALU04',
    ]
  },

  {
    key: 'flare-1c',
    name: 'Flare 1c (Orbit)',
    aliases: [],
    pattern: {
      lines: [
        [
          {"x": 1, "y": 0},
          {"x": 2, "y": 1},
          {"x": 3, "y": 0},
        ]
      ],
      openFaderPositions: [
        {"x": 1, "y": 0},
        {"x": 3, "y": 0},
      ],
      closedFaderPositions: [
        {"x": 1.5, "y": 0.5},
        {"x": 2.5, "y": 0.5},
      ],
    },
    descriptions: [
    ],
    youtubes: [
      'vF88aFHDSKk',
    ]
  },

  {
    key: 'flare-2c',
    name: 'Flare 2c (Orbit)',
    aliases: [],
    pattern: {
      lines: [
        [
          {"x": 1, "y": 0},
          {"x": 2, "y": 1},
          {"x": 3, "y": 0},
        ]
      ],
      openFaderPositions: [
        {"x": 1, "y": 0},
        {"x": 3, "y": 0},
      ],
      closedFaderPositions: [
        {"x": 1.33, "y": 0.33},
        {"x": 1.66, "y": 0.66},
        {"x": 2.33, "y": 0.66},
        {"x": 2.66, "y": 0.33},
      ],
    },
    descriptions: [
    ],
    youtubes: [
    ]
  },

  {
    key: 'flare-3c',
    name: 'Flare 3c (Orbit)',
    aliases: [],
    pattern: {
      lines: [
        [
          {"x": 1, "y": 0},
          {"x": 2, "y": 1},
          {"x": 3, "y": 0},
        ]
      ],
      openFaderPositions: [
        {"x": 1, "y": 0},
        {"x": 3, "y": 0},
      ],
      closedFaderPositions: [
        {"x": 1.25, "y": 0.25},
        {"x": 1.5, "y": 0.5},
        {"x": 1.75, "y": 0.75},
        {"x": 2.25, "y": 0.75},
        {"x": 2.5, "y": 0.5},
        {"x": 2.75, "y": 0.25},
      ],
    },
    descriptions: [
    ],
    youtubes: [
    ]
  },

  {
    key: 'jackhammer',
    name: 'Jackhammer',
    aliases: [],
    pattern: {
      lines: [
        [
          {"x": 1, "y": 0},
          {"x": 1.5, "y": 0.5},
          {"x": 2, "y": 0},
          {"x": 2.5, "y": 0.5},
          {"x": 3, "y": 0},
          {"x": 3.5, "y": 0.5},
          {"x": 4, "y": 0.5},
          {"x": 4.5, "y": 1},
          {"x": 5.5, "y": 0},
        ]
      ],
      openFaderPositions: [
        {"x": 1, "y": 0},
        {"x": 5.5, "y": 0},
      ],
      closedFaderPositions: [
        {"x": 4.5, "y": 1},
        {"x": 5, "y": 0.5},
      ],
    },
    descriptions: [
    ],
    youtubes: [
      'y7VKD02ereU',
      'PMQKZxPQmZI',
      'b0eMP1xcoGc',
    ]
  },



  {
    key: 'kermit',
    name: 'Kermit',
    aliases: [],
    pattern: {
      lines: [
        [
          {"x": 1, "y": 0},
          {"x": 1.5, "y": 0.5},
          {"x": 2, "y": 0},
          {"x": 2.5, "y": 0.5},
          {"x": 3, "y": 0},
          {"x": 4, "y": 1},
          {"x": 5, "y": 0},
        ]
      ],
      openFaderPositions: [
        {"x": 1, "y": 0},
        {"x": 5, "y": 0},
      ],
      closedFaderPositions: [
        {"x": 1, "y": 0},
        {"x": 1.5, "y": 0.5},
        {"x": 2.5, "y": 0.5},
        {"x": 3.5, "y": 0.5},
        {"x": 4.5, "y": 0.5},
        {"x": 5, "y": 0},
      ],
    },
    descriptions: [
      'Combo: chirp, chirp, 1c flare',
    ],
    youtubes: [
      'rQnMymtQ9sg',
    ]
  },

  {
    key: 'og-flare',
    name: 'OG Flare',
    aliases: [],
    pattern: {
      lines: [
        [
          {"x": 1, "y": 0},
          {"x": 2, "y": 1},
          {"x": 2.5, "y": 0},
        ]
      ],
      openFaderPositions: [
        {"x": 1, "y": 0},
        {"x": 2.5, "y": 0},
      ],
      closedFaderPositions: [
        {"x": 1.5, "y": 0.5},
        {"x": 2, "y": 1},
      ],
    },
    descriptions: [
    ],
    youtubes: [
      'bA6FDNo-D_0',
    ]
  },

  {
    key: 'og-flare-reverse',
    name: 'OG Flare (reverse)',
    aliases: [],
    pattern: {
      lines: [
        [
          {"x": 1, "y": 0},
          {"x": 1.5, "y": 1},
          {"x": 2.5, "y": 0},
        ]
      ],
      openFaderPositions: [
        {"x": 1, "y": 0},
        {"x": 2.5, "y": 0},
      ],
      closedFaderPositions: [
        {"x": 1.5, "y": 1},
        {"x": 2, "y": 0.5},
      ],
    },
    descriptions: [
    ],
    youtubes: [
    ]
  },

  {
    key: 'og-flare-chirp',
    name: 'OG Flare Chirp',
    aliases: [],
    pattern: {
      lines: [
        [
          {"x": 1, "y": 0},
          {"x": 2, "y": 1},
          {"x": 3, "y": 0},
          {"x": 3.5, "y": 0.5},
          {"x": 4, "y": 0},
          {"x": 4, "y": 0},
          {"x": 5, "y": 1},
          {"x": 6, "y": 0},
        ]
      ],
      openFaderPositions: [
        {"x": 1, "y": 0},
        {"x": 6, "y": 0},
      ],
      closedFaderPositions: [
        {"x": 2, "y": 1},
        {"x": 2.5, "y": 0.5},
        {"x": 3.5, "y": 0.5},
        {"x": 4.5, "y": 0.5},
        {"x": 5, "y": 1},
      ],
    },
    descriptions: [
    ],
    youtubes: [
    ]
  },

  {
    key: 'see-saw',
    name: 'See-Saw',
    aliases: [],
    pattern: {
      lines: [
        [
          {"x": 1, "y": 0},
          {"x": 1.33, "y": 1},
          {"x": 1.66, "y": 0},
        ],
        [
          {"x": 2, "y": 1},
          {"x": 2.33, "y": 0},
          {"x": 2.66, "y": 1},
        ],
        [
          {"x": 3, "y": 0},
          {"x": 3.33, "y": 1},
          {"x": 3.66, "y": 0},
        ],
        [
          {"x": 4, "y": 1},
          {"x": 4.33, "y": 0},
          {"x": 4.66, "y": 1},
        ],
      ],
      openFaderPositions: [
      ],
      closedFaderPositions: [
        {"x": 1, "y": 0},
        {"x": 1.66, "y": 0},
        {"x": 2, "y": 1},
        {"x": 2.66, "y": 1},
        {"x": 3, "y": 0},
        {"x": 3.66, "y": 0},
        {"x": 4, "y": 1},
        {"x": 4.66, "y": 1},
      ],
    },
    descriptions: [
    ],
    youtubes: [
      '6ZHYnUdPw3g',
    ]
  },

  {
    key: 'swing flare',
    name: 'Swing Flare',
    aliases: [],
    pattern: {
      lines: [
        [
          {"x": 1, "y": 0},
          {"x": 1.5, "y": 1},
          {"x": 2, "y": 0},
          {"x": 2.5, "y": 1},
          {"x": 3, "y": 0},
        ]
      ],
      openFaderPositions: [
      ],
      closedFaderPositions: [
        {"x": 1, "y": 0},
        {"x": 2, "y": 0},
        {"x": 2.5, "y": 1},
        {"x": 3, "y": 0},
      ],
    },
    descriptions: [
    ],
    youtubes: [
      '0HYg7w4buqE',
      'JMh9gR4tl6g',
    ]
  },

  {
    key: 'transformer',
    name: 'Transformer',
    aliases: [],
    pattern: {
      lines: [
        [
          {"x": 1, "y": 0},
          {"x": 1.5, "y": 1},
          {"x": 2, "y": 0},
        ]
      ],
      openFaderPositions: [
      ],
      closedFaderPositions: [
        {"x": 1, "y": 0},
        {"x": 1.5, "y": 1},
        {"x": 2, "y": 0},
      ],
    },
    descriptions: [
    ],
    youtubes: [
      'w3ozzXEzWbI',
      'XdkNAePjM7o',
    ]
  },



];


export default patterns;