import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import JsxParser from 'react-jsx-parser'

import {fade} from '@material-ui/core/styles/colorManipulator';
import {withStyles} from '@material-ui/core/styles';
import Autosuggest from 'react-autosuggest';
import ListItemText from '@material-ui/core/ListItemText';
import SearchIcon from '@material-ui/icons/Search';

import patternLoader from './../../code/patternLoader';
import {withSharedDataContext} from './../SharedDataContext';

import './header.css';


const styles = theme => ({
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: 0,
    marginLeft: theme.spacing.unit * 3,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing.unit * 3,
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing.unit * 9,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});


const autocompletes = patternLoader.getPatternNames().map(patternName => {
  return {
    name: patternName.name,
    value: patternName.key,
  };
});

const getSuggestions = value => {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;

  if (inputLength === 0) {
    return [];
  }

  return autocompletes.filter(item => {
    return item.name.toLowerCase().includes(inputValue);
  });
};

const getSuggestionValue = suggestion => suggestion.name;



class Search extends Component {
  state = {
    value: '',
    suggestions: [],
  };

  onChange = (event, {newValue}) => {
    this.setState({
      value: newValue
    });
  };

  onSuggestionsFetchRequested = ({value}) => {
    this.setState({
      suggestions: getSuggestions(value)
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  onSuggestionSelected = (ev, suggestion) => {
    const suggestionValue = suggestion.suggestion.value;
    this.props.history.push(`/patterns/${suggestionValue}`);
    this.setState({value: ''});
  };

  renderSuggestion = suggestion => {
    const re = new RegExp('('+this.state.value+')', 'gi');
    const highlightHtml = suggestion.name.replace(re, '<strong>$1</strong>');
    const suggestionNameHighlighted = <JsxParser jsx={highlightHtml}/>;

    return (
      <div>
        <ListItemText primary={suggestionNameHighlighted}/>
      </div>
    )
  };

  render() {
    const {classes} = this.props;

    const {value, suggestions} = this.state;

    const inputProps = {
      placeholder: 'Scratch patter name ...',
      value,
      onChange: this.onChange
    };

    return (
      <React.Fragment>

        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon/>
          </div>
          <Autosuggest
            suggestions={suggestions}
            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            onSuggestionSelected={this.onSuggestionSelected}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={this.renderSuggestion}
            inputProps={inputProps}
          />
        </div>

      </React.Fragment>
    );
  }
}

export default withSharedDataContext(
  withRouter(
    withStyles(styles)(Search)
  )
);
