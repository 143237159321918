import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import YouTube from "react-youtube";

const styles = theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  videoList: {
    width: '100%',
  },
  videoListItem: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  video: {
    width: '100%',
  }
});

class YoutubeBox extends Component {
  state = {
    isOpen: false,
  };

  onExpansionChange = (event, isExpanded) => {
    if (isExpanded === true) {
      // we will render the youtube embeds on first expansion
      this.setState({isOpen: isExpanded});
    }
  };

  render() {
    const {classes, youtubeIds} = this.props;
    const {isOpen} = this.state;

    const youtubeOpts = {
      width: '100%',
      //height: '390',
      playerVars: { // https://developers.google.com/youtube/player_parameters
        autoplay: 0
      }
    };

    const youtubeIdsCount = youtubeIds.length;

    return (
      <React.Fragment>

        <ExpansionPanel onChange={this.onExpansionChange}>

          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
            <Typography className={classes.heading}>Youtube videos ({youtubeIdsCount})</Typography>
          </ExpansionPanelSummary>

          <ExpansionPanelDetails>

            <List component="nav" className={classes.videoList}>
            {isOpen && youtubeIds.map(id =>
                <ListItem key={id} className={classes.videoListItem}>
                  <YouTube videoId={id}
                           opts={youtubeOpts}
                           key={id}
                           containerClassName={classes.video}/>
                </ListItem>
            )}
            </List>

          </ExpansionPanelDetails>
        </ExpansionPanel>

      </React.Fragment>
    );
  }
}

YoutubeBox.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(YoutubeBox);
