import React, {Component} from 'react';

import patternLoader from './../code/patternLoader';

const SharedDataContext = React.createContext({});

export class SharedDataProvider extends Component {
  state = {
    patternNames: patternLoader.getPatternNames(),
    currentPatternConf: {},
  };

  loadCurrentPatternConfByKey = (patternKey) => {
    const patternConf = patternLoader.loadPatternConfByKey(patternKey);
    this.setState({currentPatternConf: patternConf});
  };


  render() {
    const {children} = this.props;

    return (
      <SharedDataContext.Provider value={{
        ...this.state,
        loadPatternConfByKey: this.loadCurrentPatternConfByKey,
      }}>
        {children}
      </SharedDataContext.Provider>
    );
  }
}

export const SharedDataConsumer = SharedDataContext.Consumer;

export const withSharedDataContext = (Component) => {
  return (props) => {
    return (
      <SharedDataConsumer>
        {(context) => {
          return <Component {...props} sharedDataContext={context}/>
        }}
      </SharedDataConsumer>
    )
  }
};