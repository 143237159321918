import patternDefs from '../data/scratch-pattern-defs.js';

const addDefaultsToPatternDef = (patternDef) => {
  return patternDef;
};

const loadPatternConfByKey = (patternKey) => {
  const index = patternDefs.findIndex(x => x.key === patternKey);

  let patternDef = {};
  if (index !== -1) {
    patternDef = patternDefs[index];
  }

  return addDefaultsToPatternDef(patternDef);
};

const getPatternNames = () => {
  return patternDefs.map(patternDef => {
    return {
      name: patternDef.name,
      key: patternDef.key,
    }
  });
};


export default {
  getPatternNames,
  loadPatternConfByKey,
};