import React from 'react';

import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import {withSharedDataContext} from './../SharedDataContext';
import {Link} from "react-router-dom";

class Sidebar extends React.Component {

  onClick = () => {
    // when on mobile, we collapse the sidebar after click
    if (this.props.onCloseDrawerMobile) {
      this.props.onCloseDrawerMobile();
    }
  };

  render() {
    const patternNames = this.props.sharedDataContext.patternNames || [];

    return (
      <div>
        <Divider/>
        <List>
          {patternNames.map(patternName => (
            <ListItem key={patternName.key}
                      button
                      component={Link}
                      to={`/patterns/${patternName.key}`}
                      onClick={this.onClick}
            >
              <ListItemText primary={patternName.name}/>
            </ListItem>
          ))}
        </List>
        <Divider/>
        <List>
          <ListItem button key={`home`} component={Link} to={`/`} onClick={this.onClick}>
            <ListItemText primary={`Homepage`}/>
          </ListItem>
        </List>
      </div>
    );
  }
}

export default withSharedDataContext(Sidebar);