import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Route, Switch} from "react-router-dom";

import {withStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import Home from './../home/Home';
import NotFound from './NotFound';
import PatternList from './../pattern/PatternList';
import PatternView from './../pattern/PatternView';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  container: {
    //paddingRight: theme.spacing.unit * 2,
    //paddingLeft: theme.spacing.unit * 2,
    //marginRight: 'auto',
    //marginLeft: 'auto',

    // Full width for (xs, extra-small: 0px or larger) and (sm, small: 600px or larger)
    [theme.breakpoints.up('md')]: {  // medium: 960px or larger
      width: 720,
    },
    [theme.breakpoints.up('lg')]: {  // large: 1280px or larger
      width: 970,
    },
    [theme.breakpoints.up('xl')]: {  // extra-large: 1920px or larger
      width: 1166,
    },
  },
});

class Main extends Component {

  render() {
    const {classes} = this.props;

    return (
      <React.Fragment>
        <Paper className={classes.root} elevation={1}>

          <div className={classes.container}>

            <Switch>
              <Route path="/" exact={true} component={Home}/>
              <Route path="/patterns" exact={true} component={PatternList}/>
              <Route path="/patterns/:key" exact={true} component={PatternView}/>
              <Route component={NotFound}/>
            </Switch>

          </div>

        </Paper>
      </React.Fragment>
    );
  }
}

Main.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Main);
