import React, {Component} from 'react';
import {Link} from "react-router-dom";

import {fade} from '@material-ui/core/styles/colorManipulator';
import {withStyles} from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
//import ListIcon from '@material-ui/icons/List';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import Search from './Search';

const drawerWidth = 240;

const styles = theme => ({
  root: {
    width: '100%',
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: 0,
    marginLeft: theme.spacing.unit * 3,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing.unit * 3,
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing.unit * 9,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
});

class Header extends Component {

  render() {
    const {classes} = this.props;

    return (
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            onClick={this.props.onHandleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon/>
          </IconButton>

          <Typography variant="h6" color="inherit">
            <Link to={`/`} style={{textDecoration: 'none', color: '#ffffff'}}>
              TTMr
            </Link>
          </Typography>

          <Search/>

          <div className={classes.grow}/>
          {/*<div className={classes.sectionMobile}>*/}
            {/*<Link to={`/patterns`}>*/}
              {/*<IconButton style={{color: '#ffffff'}}>*/}
                {/*<ListIcon/>*/}
              {/*</IconButton>*/}
            {/*</Link>*/}
          {/*</div>*/}

        </Toolbar>
      </AppBar>
    );
  }
}

export default withStyles(styles)(Header);
